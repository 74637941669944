/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/* TODO: Fix the background stretching thing when it has scroll vs no scroll bar */
body {
    background-image: url("./images/blue-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Georgia", serif;
    color: #ffffff;
}

p {
    font-size: 18px;
}

.top {
    text-align: center;
}

.centre-text {
    text-align: center;
}

.info-box {
    background-color: rgba(34, 109, 59, 0.8);
    border: 1px solid white;
    border-radius: 10px;
    width: 35em;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding-left: 15px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.centre {
    margin-left: auto;
    margin-right: auto;
}

.window {
    opacity: 0.5;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
}

#social-media {
    display: flex;
    flex-direction: column;
}

/* Keep the tabs in-line and give the active tab a border to make it stand out */
.tab-list {
    border-bottom: 3px solid #800080;
    text-align: center;
    padding-left: 0;
}

.tab-list-item {
    background-color: purple;
    color: #ffffff;
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-item:hover {
    background-color: #9b4a9b;
}

.tab-list-active {
    background-color: white;
    color: black;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}

.translucent-background {
    background-color: rgba(0, 0, 0, 0.3);
}

#about-us {
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    width: 75%;
    color: white;
}

.text-tab {
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    width: 75%;
    color: white;
}

#nav-bar {
    background-color: rgb(69, 31, 85);
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
}

.nav-button {
    background-color: white;
    width: 80px;
    height: 50px;
    text-align: center;
    position: relative;
    top: 5px;
}



.float-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.float-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    /*background-color: rgba(128,0,128, 0.7);*/
    /*border: 1px solid white;*/
    /*border-radius: 10px;*/
    /*width: 35em;*/
    /*position: relative;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*padding-left: 15px;*/
    /*padding-right: 5px;*/
    /*padding-bottom: 10px;*/
}

/*.float-child.left {*/
/*    !*float: left;*!*/
/*    !*left: 15em;*!*/

/*}*/

/*.float-child.right {*/
/*    !*float: right;*!*/
/*    !*right: 15em;*!*/

/*}*/
